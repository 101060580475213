ul.nav > li > a.active {
    color: #F6921E;
}
.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
    color: #F6921E;
}
ul.nav>li>a:hover.active {
	color:#F6921E;
}
.ms-layer.sbut5 {
    background-color: #F6921E;
}
.feature-box1:hover .iconbox-tiny, .feature-box1.active .iconbox-tiny {
    background-color: #F6921E;
    border: 1px solid #F6921E;
}
a.read-more {
    color: #F6921E;
}
.text-orange-2 {
    color: #F6921E;
}
.btn.btn-orange-2 {
    background-color: #F6921E;
}
.iconlist li i {
    color: #F6921E;
}
.btn.btn-border.orange-2 {
    border-color: #F6921E;
    color: #F6921E;
}
.btn.btn-border:hover.orange-2 {
	background-color:#F6921E;
	border-color:#F6921E;
}
.team-name-holder.bgcolor {
    background-color: #F6921E;
}
.social-icons-1 li a:hover, .social-icons-1 li a.active {
    color: #F6921E;
}
.pricetable-holder.active .title {
    background-color: #F6921E;
}
.post-date-box {
    background-color: #F6921E;
}
.post-date-box .arrow-down {
    border-top: 20px solid #F6921E;
}
.blog-post-info span.green {
    color: #F6921E;
}

.footer-tags li a:hover, .footer-tags li a.active {
    border: 1px solid #F6921E;
    color: #F6921E;
}
.scrollup {
    background-color: #F6921E;
}
.social-icons-3 li a:hover, .social-icons-3 li a.active {
    background-color: #F6921E;
}
.social-icons-3 li a {
    color: #F6921E;
}
a.topbar-btn:hover, a.topbar-btn.active {
    background-color: #F6921E;
}
.slide-controls-color-13 .owl-theme .owl-controls .owl-page span {
    background: #F6921E none repeat scroll 0 0;
}
.feature-box3:hover .iconbox-xlarge, .feature-box3.active .iconbox-xlarge {
    background-color: #F6921E;
}
.feature-box3 .iconbox-xlarge {
    color: #F6921E;
}
.ms-layer.sbut4 {
    background-color: #F6921E;
}
.digit {
    background-color: #F6921E;
}
.tabs1 li.active a {
    background-color: #F6921E;
    border: 1px solid #F6921E;
}
.tabs1 li a:hover {
    color: #F6921E;
}
.iconlist.orange li i {
    color: #F6921E;
}
.feature-box4 .iconbox-xtiny {
    background-color: #F6921E;
}
.feature-box4:hover h5 {
    color: #F6921E;
}
.team-holder2 .img-holder .icon-box {
    background-color: #F6921E;
}
.feature-box5:hover h4, .feature-box5:hover .icon-plain-msmall, .feature-box5.active h4, .feature-box5.active .icon-plain-msmall {
    color: #F6921E;
}
.tabs2 li.active a {
    background-color: #F6921E;
    border-right: 1px solid #F6921E;
}
.tabs2 li a:hover {
    background-color: #F6921E;
}
.usefull-links.orange li i {
    color: #F6921E;
}
.newsletter .input_submit {
    background-color: #F6921E;

}
.social-icons-3.orange li a:hover {
    background-color: #F6921E;
}
.scrollup.orange {
    background-color: #F6921E;
}
.scrollup.orange2 {
    background-color: #F6921E;
}
.scrollup.violet {
   background-color: #F6921E;
}
ul.nav.orange2 > li > a.active {
    color: #F6921E;
}
ul.nav.orange2 > li > a.active:hover {
    color: #F6921E;
}
.navbar-default.orange2 .navbar-nav > li > a:focus, .navbar-default.orange2 .navbar-nav > li > a:hover {
    color: #F6921E;
}
.ms-layer.sbut5.orange {
    background-color: #F6921E;
}
.tabs li.active a {
    background-color: #F6921E;
}
.iconlist.orange2 li i {
    color: #F6921E;
}
.section-orange-2 {
    background-color: #F6921E;
}
.testimonials-holder.two:hover, .testimonials-holder.two.active {
    background-color: #F6921E;
}
.contact-info-holder:hover .iconbox-xmedium, .contact-info-holder.active .iconbox-xmedium {
    background-color: #F6921E;
}
.contact-info-holder .iconbox-xmedium {
    color: #F6921E;
}
.socialicon-holder .social-icons-3 li a:hover, .socialicon-holder .social-icons-3 li a.active {
    background-color: #F6921E;
    border: 1px solid #F6921E;
}
.btn.btn-orange-2.dark {
    background-color: #F6921E;
}
.feature-box7:hover, .feature-box7.active {
    background-color: #F6921E;
}
.feature-box8:hover .iconbox-small, .feature-box8.active .iconbox-small {
    background-color: #F6921E;
    border: 1px solid #F6921E;
}
.feature-box8:hover h4, .feature-box8.active h4 {
    color: #F6921E;
}
.feature-box8 .iconbox-small {
    color: #F6921E;
}
.smk_accordion .accordion_in.st2.acc_active > .acc_head {
    color: #F6921E;
}
.progressbar-style1 .progress-bar {
    background-color: #F6921E;
}
.testimonials2 .imgholder {
    background-color: #F6921E;
}
.team-holder3 .social-icons-3 li a:hover, .team-holder3 .social-icons-3 li a.active {
    background-color: #F6921E;
}
.post-date-box.violet {
    background-color: #F6921E;
}
.newsletter .input_submit.violet {
    background-color: #F6921E;
}
.usefull-links.violet li i {
    color: #F6921E;
}
.section-green {
    background-color: #F6921E;
}
.feature-box9:hover .iconbox-xlarge, .feature-box9.active .iconbox-xlarge {
    background-color: #F6921E;
    border: 1px solid #F6921E;
}
.feature-box9 .iconbox-xlarge {
    color: #F6921E;
}
.feature-box9:hover h3, .feature-box9.active h3 {
    color: #F6921E;
}
.iconlist.green2 li i {
    color: #F6921E;
}
.blog-holder2 .post-date-box {
    background-color: #F6921E;
}
.testimonials3:hover .img-holder, .testimonials3.active .img-holder {
    background-color: #F6921E;
}
.testimonials3:hover .arrow-down, .testimonials3.active .arrow-down {
    border-top: 20px solid #F6921E;
}
.ms-layer.text59 {
    background-color: #F6921E;
}
.ms-layer.sbut2:hover {
    background-color: #F6921E;
    color: #fff;
}
.title-line-8 {
    background-color: #F6921E;
}
.feature-box10:hover, .feature-box10.active {
    background-color: #F6921E;
    border: 1px solid #F6921E;
}
.feature-box11 .iconbox-xtiny {
    background-color: #F6921E;
}
.post-infoicon {
    background-color: #F6921E;
}
.tabs3 li.active a {
    background-color: #F6921E;
    border-right: 1px solid #F6921E;
}
.tabs3 li a:hover {
    background-color: #F6921E;
}
.testimonials5 .social-icons-2 li a:hover, .testimonials5 .social-icons-2 li a.active {
    background-color: #F6921E;
    border: 1px solid #F6921E;
}
.topbar-middle-logo {
    background-color: #F6921E;
}
.ms-layer.offerbadge.orange-2 {
    background-color: #F6921E;
}
.ms-layer.text62 span {
    color: #F6921E;
}
.feature-box12:hover .iconbox-small, .feature-box12.active .iconbox-small {
    background-color: #F6921E;
}
.feature-box12:hover .read-more.white, .feature-box12.active .read-more.white {
    color: #F6921E;
}
.feature-box13:hover .iconbox-medium, .feature-box13.active .iconbox-medium {
    background-color: #F6921E;
    border: 1px solid #F6921E;
}
a.read-more.dark:hover {
    color: #F6921E;
}
.feature-box14 .digit {
    background-color: #F6921E;
}
.team-holder4:hover .icon-holder, .team-holder4.active .icon-holder {
    background-color: #F6921E;
}
.team-holder4:hover h5, .team-holder4.active h5 {
    color: #F6921E;
}
.pricetable-holder2 th .title-header.active {
    background-color: #F6921E;
}
.feature-box15:hover, .feature-box15.active {
    background-color: #F6921E;
}
.feature-box15 {
    border: 1px solid #F6921E;
}
.feature-box16:hover .iconbox-smedium, .feature-box16.active .iconbox-smedium {
    background-color: #F6921E;
}
.feature-box16:hover h4, .feature-box16.active h4 {
    color: #F6921E;
}
.social-icons-2 li a {
    background-color: #F6921E;
}
.pricetable-holder3:hover .inner-holder, .pricetable-holder3.active .inner-holder {
    background-color: #F6921E;
}
.pricetable-holder3:hover, .pricetable-holder3.active {
    border: 1px solid #F6921E;
}
.feature-box17:hover, .feature-box17.active {
    background-color: #F6921E;
}
.feature-box19 .iconbox-small {
    color: #F6921E;
}
.feature-box19:hover .iconbox-small, .feature-box19.active .iconbox-small {
    background-color: #F6921E;
    border: 1px solid #F6921E;
}
.feature-box19:hover h4, .feature-box19.active h4 {
    color: #F6921E;
}
.feature-box5.two:hover, .feature-box5.two.active {
    background-color: #F6921E;
}
.feature-box5.two:hover .arrow-down, .feature-box5.two.active .arrow-down {
    border-top: 20px solid #F6921E;
}
.feature-box20:hover .iconbox-tiny, .feature-box20.active .iconbox-tiny {
    background-color: #F6921E;
}
.feature-box22 .iconbox-xlarge .inner-box {
    background-color: #F6921E;
}
.feature-box23:hover, .feature-box23.active {
    background-color: #F6921E;
}
.btn.appstore.orange {
    background-color: #F6921E;
}
.btn.appstore.white:hover {
    background-color: #F6921E;
}
.personal-info.orange-2 li {
    color: #F6921E;
}
.category-links.orange-2 li a:hover, .category-links.orange-2 li a.active {
    color: #F6921E;
}
.sidebar-posts:hover h6, .sidebar-posts.active h6 {
    color: #F6921E;
}
.tags-2 li a:hover, .tags-2 li a.active {
    background-color: #F6921E;
    border: 1px solid #F6921E;
}
.btn.btn-green-4 {
    background-color: #F6921E;
}
.tabs15 li.active a {
    background-color: #F6921E;
    border: 1px solid #F6921E;
}
.tabs15 li a:hover {
    color: #F6921E;
}
ul.sitemap li a {
    color: #F6921E;
}
.error-search-box .input_submit {
    background-color: #F6921E;
    border: 1px solid #F6921E;
}
.cbp-l-filters-button.three .cbp-filter-item.cbp-filter-item-active {
    color: #F6921E;
}
.cbp-l-filters-button.two .cbp-filter-item.cbp-filter-item-active {
    border-color:#F6921E;
}
.cbp-l-filters-button.two .cbp-filter-item:hover {
    color: #F6921E;
}
.cbp-l-filters-button.two .cbp-filter-counter {
    background-color: #F6921E;
}

.cbp-l-filters-button.two .cbp-filter-counter::before {
    border-top: 4px solid #F6921E;
}
.blog-holder-12 .image-holder .overlay .icon {
    background-color: #F6921E;
}
.blog-pagenation li a:hover, .blog-pagenation li a.active {
    background-color: #F6921E;
    border: 1px solid #F6921E;
}
a.loadmore-but.orange-2 {
    background-color: #F6921E;
}
.smart-forms .btn-primary.orange-2 {
    background-color: #F6921E;
}



/*rgba color*/

.team-holder3 .img-holder .overlay {
    background-color: rgba(255, 135, 28, 0.9);
}
.section-overlay.orange-2 {
    background-color: rgba(255, 135, 28, 0.9);
}

.pricetable-holder .arrow {
    background: url(../../images/colors/orange/site-img07.png);
}
.pricetable-holder .arrow.two {
    background: url(../../images/colors/orange/site-img08.png);
}
