.header-report {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.container-table {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.report-table {
  min-width: 750px;
}
.box-input-date {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 25px;
}
@media (max-width: 1000px) {
  .box-input-date {
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .box-input-date {
    margin-top: 120px;
  }
}
.input-date {
  line-height: 25px !important;
  width: 140px;
}
.input-date:hover,
.label-date:hover {
  cursor: pointer;
}
.label-date {
  margin: 0px 8px 0 10px;
}
.button-getReports {
  height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.report-thead {
  border-bottom: 2px solid gray;
  margin-bottom: 10px;
}
.report-tr {
  border-bottom: 1px solid gray;
  height: 50px;
}
.empty-report {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.loading-reports {
  width: 100%;
  margin-top: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker {
  font-size: 1rem !important;
}
.react-datepicker__day,
.react-datepicker__day-name {
  width: 2.5rem !important;
  line-height: 2.5rem !important;
}
.react-datepicker__current-month {
  font-size: 1.5rem !important;
}
