/*	
--------------------------------------------------
@ * Smart Forms v3.1.0
  * http://www.doptiq.com/smart-forms
  * Last updated 05 / JUNE / 2015
  * Product developed by Elflaire 
  * http://codecanyon.net/user/elflaire
-------------------------------------------------- 
*/

/* Roboto google font import 
--------------------------------------- */
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300);

html, body{
	border: 0;
	margin: 0;
	padding: 0;
}


/* @form wrappers 
---------------------------------- */
.smart-wrap{ padding:0 20px; }
.smart-forms, 
.smart-forms *{
	box-sizing: border-box;
}

.smart-forms {
	line-height: 1.231;
	font-weight: normal;
	font-size: 14px;
	color: #727272;
}

.smart-container{
	background:#fff;
/*	margin:50px auto;	
	-webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
	-moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
	-o-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
	-webkit-border-radius:6px 6px 0 0;
	-moz-border-radius:6px 6px 0 0;
	-o-border-radius:6px 6px 0 0;
	border-radius:6px 6px 0 0;*/
	 
}

/* @form container width 
/* @if you want to change the form container width change the values below 
/* @alternatively you can add yours eg .wrap4{ max-width:200px; } 
---------------------------------------------------------------------------- */
.wrap-0{ max-width:952px; }
.wrap-1{ max-width:852px; }
.wrap-2{ max-width:652px; }
.wrap-3{ max-width:100%; }

/* @form helper classes 
--------------------------------------------------------------- */
.smart-forms .section{ margin-bottom:22px; }
.smart-forms .smart-link{ color:#4ebd4a; text-decoration:none; }
.smart-forms .smart-link:hover{ text-decoration: underline; }
.smart-forms .tagline{ height:0; border-top:1px solid #CFCFCF; text-align:center;  }
.smart-forms .tagline span{ 
	text-transform:uppercase; 
	display:inline-block;
	position:relative;
	padding:0 15px; 
	background:#fff; 
	color:#4ebd4a;
	top:-10px;  
}

.smart-forms .section.less-width{ 
	width:45%;
 }


/* @form label + field :: field class is useful for validation 
---------------------------------------------------------------------- */
.smart-forms .field{ display:block; position:relative; }
.smart-forms .field-icon i { color:#BBB; position:relative; }
.smart-forms .field-label { display: block; margin-bottom: 7px; }
.smart-forms .field-label.colm{ padding-top:12px; }
.smart-forms .field-label em{ 
	color:#e74c3c;
	font-size:14px;
	font-style:normal;
	display:inline-block;
	margin-left:4px;
	position:relative; 
	top:3px;  
}

.smart-forms fieldset{ 
	border:0;
	display:block;
}

/* @form header section 
----------------------------------------- */
.smart-forms .form-header{ 
	overflow:hidden;
	position:relative;
	padding:25px 30px;
	border-radius:5px 5px 0 0 ;
}
	
.smart-forms .form-header h4 { 
	font-family:"Roboto", Arial, Helvetica, sans-serif;
	text-align:left;
	font-weight:300; 
	font-size:32px; 
	padding:0;
	margin:0;
		 
}

.smart-forms .form-header h4 i { 
	font-size:38px;
	position:relative; 
	margin-right:10px;
	top:2px; 
}

/* @header themes :: primary + lite 
---------------------------------------------- */
.smart-forms .header-primary {	
	background-color:#4ebd4a;
	border-top:1px solid #A7D065;  
	border-bottom:5px solid #3c9b39;
}

.smart-forms .header-lite{ 
	background:#F3F5FA;
	border-top:1px solid #A7D065; 
	border-bottom:1px solid #D9DDE5; 
}

.smart-forms .header-lite:before{
	content:"";
	background-color:#4ebd4a;  
	position:absolute;
	height:8px;  
	z-index:1; 
	top:0px;
	right:0;  
	left:0;
}

.smart-forms .header-primary h4{ color:#fff; }
.smart-forms .header-lite h4{ color:#5D6A87; padding-top:5px; }

/* @remove rounded corners form headers 
----------------------------------------------------------------- */
.smart-flat, 
.smart-flat .form-header{
	border-radius:0;
}

/* @form body + footer 
------------------------------------------------------------------- */	
.smart-forms .form-body{ padding:40px 30px; padding-bottom:20px; }
.smart-forms .form-footer {
	overflow:hidden;
	padding:0 0;

}

/* @crossbrowser placeholder styling :: modern browsers only IE10+
------------------------------------------------------------------------ */
.smart-forms input[type=search] { -webkit-appearance: textfield; }
.smart-forms ::-webkit-search-decoration, 
.smart-forms ::-webkit-search-cancel-button { -webkit-appearance: none; }
.smart-forms input:invalid { box-shadow: none;  }
.smart-forms input::-webkit-input-placeholder,
.smart-forms textarea::-webkit-input-placeholder { color: #AAAAAA; }
.smart-forms input:focus::-webkit-input-placeholder,
.smart-forms textarea:focus::-webkit-input-placeholder { color: #D6DBE0; }
.smart-forms input:-moz-placeholder,
.smart-forms textarea:-moz-placeholder { color: #AAAAAA; }
.smart-forms input:focus:-moz-placeholder,
.smart-forms textarea:focus:-moz-placeholder { color: #D6DBE0; }
.smart-forms input::-moz-placeholder,
.smart-forms textarea::-moz-placeholder { color: #AAAAAA; opacity: 1; }
.smart-forms input:focus::-moz-placeholder,
.smart-forms textarea:focus::-moz-placeholder { color: #D6DBE0; opacity: 1; }
.smart-forms input:-ms-input-placeholder,
.smart-forms textarea:-ms-input-placeholder { color: #AAAAAA; }
.smart-forms input:focus:-ms-input-placeholder,
.smart-forms textarea:focus:-ms-input-placeholder { color: #D6DBE0; }

/* @element general styling :: fonts :: adjust accordingly
------------------------------------------------------------- */
.smart-forms label, 
.smart-forms input,
.smart-forms button,
.smart-forms select,  
.smart-forms textarea {
	margin: 0;  
	font-size: 14px;
	font-family:  "Roboto", Arial, Helvetica, sans-serif;
	font-weight:400;
	color: #626262;
	outline:none;
}

/* @remove browser specific styling
----------------------------------------------- */
.smart-forms .gui-input,
.smart-forms .gui-textarea,
.smart-forms .select > select,
.smart-forms input[type="button"],
.smart-forms input[type="submit"],
.smart-forms input[type="search"],
.smart-forms .select-multiple select {
	-webkit-tap-highlight-color:transparent;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0px;
}

.smart-forms input[type="search"]::-webkit-search-decoration,
.smart-forms input[type="search"]::-webkit-search-cancel-button,
.smart-forms input[type="search"]::-webkit-search-results-button,
.smart-forms input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

/* @labels font-size styling :: adjust to fit your needs 
--------------------------------------------------------- */
.smart-forms .switch, 
.smart-forms .option,
.smart-forms .field-label{ font-size:14px; }

/* @prevent user selection for usability purposes
----------------------------------------------------- */
.smart-forms .radio,
.smart-forms .button,  
.smart-forms .checkbox,
.smart-forms .select .arrow,
.smart-forms .switch > label,
.smart-forms .ui-slider .ui-slider-handle{
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* @universal rules for all elements 
---------------------------------------------------- */
.smart-forms .radio,
.smart-forms .button,
.smart-forms .tooltip,
.smart-forms .checkbox,  
.smart-forms .gui-input,
.smart-forms .notification,
.smart-forms .gui-textarea,
.smart-forms .select > select,
.smart-forms .select-multiple select{ 
	-webkit-transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;
	border-radius: 0;
	outline:none;
}

.smart-forms .select > select,
.smart-forms .select-multiple select{
	-webkit-transition:none;
	transition:none;
}

/* @control border-size :: color etc for these elements 
----------------------------------------------------------- */
.smart-forms .select,
.smart-forms .gui-input,
.smart-forms .gui-textarea,
.smart-forms .select > select,
.smart-forms .select-multiple select{
	background: #fff;
	position: relative;
	vertical-align: top;
	border: 1px solid #e4e4e4;
	display: -moz-inline-stack;
    display: inline-block;
    *display: inline;
	color: #626262;
	outline:none;
	height: 42px;
	width: 100%;
	*zoom: 1;
}

/* input dark
----------------------------------------------------------- */
.smart-forms.dark .select,
.smart-forms.dark .gui-input,
.smart-forms.dark .gui-textarea,
.smart-forms.dark .select > select,
.smart-forms.dark .select-multiple select{
	background: #242424;
	position: relative;
	vertical-align: top;
	border: 1px solid #545353;
	display: -moz-inline-stack;
    display: inline-block;
    *display: inline;
	color: #fff;
	outline:none;
	height: 42px;
	width: 100%;
	*zoom: 1;
}

.smart-forms.dark .state-success .gui-input, .smart-forms.dark .state-success .gui-textarea, .smart-forms.dark .state-success.select > select, .smart-forms.dark .state-success.select-multiple > select, .smart-forms.dark .state-success input:hover + .checkbox, .smart-forms.dark .state-success input:hover + .radio, .smart-forms.dark .state-success input:focus + .checkbox, .smart-forms.dark .state-success input:focus + .radio, .smart-forms.dark .state-success .checkbox, .smart-forms.dark .state-success .radio {
    background: #242424 none repeat scroll 0 0;
    border-color: #545353;
}

/* @form element :focus state 
-------------------------------------------------------- */
.smart-forms.dark .gui-input:focus,
.smart-forms.dark .gui-textarea:focus,
.smart-forms.dark .select > select:focus,
.smart-forms.dark .select-multiple select:focus{ 
    color: #fff;
    background: #242424;
	border:1px solid #727272;  
	-o-box-shadow:0px 0px 3px #4EBD4A inset;	
	box-shadow:0px 0px 3px #4EBD4A inset;
    outline: none; 

}

/* input less width
----------------------------------------------------------- */

.section-form.less-width {
	position: relative;
   width: 49.8%;
   margin-bottom:15px;
   display: inline-block;
}


/* @styling inputs and textareas 
------------------------------------------- */
.smart-forms .gui-input, 
.smart-forms .gui-textarea { padding:10px; }
.smart-forms .gui-textarea {    
    resize: none;
	line-height: 19px;
	overflow: auto;
	max-width:100%;
    height: 96px;
}

/* @hint below textareas 
---------------------------------------- */
.smart-forms .input-hint {
    padding: 10px;
	display: block;
	margin-top: -1px;
	line-height: 16px;
	position: relative; 
    background: #F5F5F5;
    border: 1px solid #CFCFCF;	   
	font-family:Arial, Helvetica, sans-serif;
	border-radius: 0;
    font-size: 11px;
    color: #999;
}

.smart-forms .input-hint.style1 {
    background:none;
    border: none;	   
    color: #727272;
}

/* @form selects :: dropdowns 
-------------------------------------------------- */
.smart-forms .select { border:0; z-index:10; }
.smart-forms .select > select {
	display: block;
    padding:9px 10px; 
    color: #626262;
    background: #F5F5F5;   	
     border: 1px solid #CFCFCF;	
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:normal;
	outline:none;
	text-indent: 0.01px;
    text-overflow: ''; 
	z-index:10;
	margin: 0;	
}

.smart-forms .select > select::-ms-expand { display: none; }
.smart-forms .select .arrow {
	position: absolute;
	top: 9px;
	right: 4px;
	width: 24px;
	height: 24px;
	color:#9F9F9F;
	pointer-events:none;
	z-index:16;
}

.smart-forms .select .arrow:after,
.smart-forms .select .arrow:before {
	content: '';
	position: absolute;
	font:12px "Consolas", monospace;
	font-style:normal;
	pointer-events:none;
	display:none\9;
	left:5px;
	
}

.smart-forms .select .arrow:before { content:'\25BC'; bottom:4px; }
.smart-forms .select .double:after { content:'\25B2'; top:-1px;  }
.smart-forms .select .double:before { content:'\25BC'; bottom:-1px; }
.smart-forms .select-multiple select { 
	width:100%; 
	height: 123px;
	padding: 10px;
}

/* @file inputs :: file uploaders 
-------------------------------------------------------- */
.smart-forms .file{ display:block; width:100%; }
.smart-forms .file .gui-file{
	width:100%;
	height:100%;
	cursor:pointer;
	padding:8px 10px;
	position:absolute;
	-moz-opacity:0;
	opacity: 0;
	z-index:11;
	bottom:0;	
	right:0;
}

.smart-forms .file .button {
	position: absolute;
	top: 4px;
	right: 4px;
	float: none;
	height: 34px;
	line-height: 34px;
	padding: 0 16px;
	z-index:10;
}

/* @form element :hover state 
-------------------------------------------- */
.smart-forms .gui-input:hover,
.smart-forms .gui-textarea:hover,
.smart-forms .select > select:hover,
.smart-forms .select-multiple select:hover, 
.smart-forms .gui-input:hover ~ .input-hint,
.smart-forms .file .gui-file:hover + .gui-input,
.smart-forms .gui-textarea:hover ~ .input-hint{
	border-color: #6BC868;
}

/* @form element :focus state 
-------------------------------------------------------- */
.smart-forms .gui-input:focus,
.smart-forms .gui-textarea:focus,
.smart-forms .select > select:focus,
.smart-forms .select-multiple select:focus{ 
    color: #3c3c3c;
    background: #fff;
	border:1px solid #4EBD4A;  
	-o-box-shadow:0px 0px 3px #4EBD4A inset;	
	box-shadow:0px 0px 3px #4EBD4A inset;
    outline: none; 

}

.smart-forms .select > select:focus {	
	z-index:10;
	z-index:20\9;
}

.smart-forms .gui-textarea:focus{  height: 120px; }
.smart-forms .select > select:focus { z-index:10; z-index:20\9; }
.smart-forms .gui-input:focus ~ .field-icon i,
.smart-forms .gui-textarea:focus ~ .field-icon i{ color:#4EBD4A; }
.smart-forms .select-multiple select:focus, 
.smart-forms .gui-input:focus ~ .input-hint,
.smart-forms .gui-textarea:focus ~ .input-hint, 
.smart-forms .file .gui-file:focus + .gui-input{ border-color: #4EBD4A; }
.smart-forms .select > select:focus + .arrow{ color:#4EBD4A; }


/* @radio + checkbox option elements 
----------------------------------------------------- */ 
.smart-forms .option {
    position: relative;	
	padding-right:15px;    
	display: inline-block;
	vertical-align:middle;
}
.smart-forms .option > input {
    position: absolute;
    height: inherit;
    width: inherit;
    opacity: 0;
	left: 0;
}

.smart-forms .checkbox, 
.smart-forms .radio { 
	position:relative;
	margin-right:2px;
	background: #fff;
	display: inline-block;
    border: 3px solid #CFCFCF;	
	margin-bottom:0;
	margin-top:0;	
	height: 21px;
	width: 21px;
	top:4px;
	
}

.smart-forms .checkbox:before, 
.smart-forms .radio:before {
    content: '';
    display: none;
	-o-box-sizing: content-box;
	box-sizing: content-box;
	padding: 0;
	margin: 0;
}

.smart-forms input:checked + .checkbox:before, 
.smart-forms input:checked + .radio:before {
    display: block;
}

.smart-forms .checkbox:before {
    position: absolute;
    top: 4px;
    left: 3px;
    width: 6px;
    height: 3px;
    border: solid #4ebd4a;
    -webkit-border-width: 0 0 3px 3px;
	-moz-border-width: 0 0 3px 3px;
	border-width: 0 0 3px 3px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.smart-forms input:checked + .checkbox, 
.smart-forms input:checked + .radio{ border: 3px solid #4ebd4a; }
.smart-forms .radio { 
	border-radius: 20px;
 
}
.smart-forms .radio:before {
    margin: 4px;
    width: 7px;
    height: 7px;
    background: #4ebd4a; 
	border-radius: 10px;
}


/* @radio + checkbox :hover state 
-------------------------------------------------- */
.smart-forms input:hover + .checkbox, 
.smart-forms input:hover + .radio{
    border-color:#6BC868;
	
}

/* @radio + checkbox :focus state 
--------------------------------------------------- */
.smart-forms input:focus + .checkbox, 
.smart-forms input:focus + .radio{ border-color: #4ebd4a; }
.smart-forms input:focus + .radio:before{ background: #4ebd4a; }
.smart-forms input:focus + .checkbox:before{ border-color: #4ebd4a; }

/* @toggle switch elements 
-------------------------------------------------- */
.smart-forms .switch { 
	cursor:pointer; 
	position: relative; 
	padding-right:10px;
    display: inline-block;
	margin-bottom:5px;
	height: 26px;
}
.smart-forms .switch > .switch-label {
	cursor:pointer;
    display: inline-block;
    position: relative;
    height: 25px;
	width: 58px;
	color: #fff;
    font-size: 10px;
    font-weight: bold;
	line-height: 20px;
    text-align: center;
    background: #D7D7D7;
    border: 2px solid #D7D7D7;
	text-transform: uppercase;
	font-family:Helvetica, Arial, sans-serif;
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
	
}
.smart-forms .switch > .switch-label + span{ display:inline-block; padding-left:5px; position:relative; top:-7px; }
.smart-forms .switch > .switch-label:before {
    content: attr(data-off);
    position: absolute;
    top: 1px;
    right: 3px;
    width: 33px;
}

.smart-forms .switch > .switch-label:after {
	content:"";	
    margin: 1px;
    width: 19px;
    height: 19px;
	display: block;
    background: #fff;
}

.smart-forms .switch > input {
	-webkit-appearance: none;
	position: absolute;
    width: inherit;
    height: inherit;
    opacity: 0;
	left: 0;
	top: 0;
   
}

/* @toggle switch focus state 
-------------------------------------------------------------- */
.smart-forms .switch > input:focus { outline: none; }
.smart-forms .switch > input:focus + .switch-label { color: #fff; border-color: #C7C7C7; background:#C7C7C7; }
.smart-forms .switch > input:focus + .switch-label:after { background: #fff; }

/* @toggle switch normal state 
--------------------------------------------------------------- */
.smart-forms .switch > input:checked + .switch-label {
    border-color: #4ebd4a;
	background: #4ebd4a; 
	padding-left: 33px;
	color: white;
}

.smart-forms .switch > input:checked + .switch-label:before {
    content: attr(data-on);
    left: 1px;
	top:1px;
}

.smart-forms .switch > input:checked + .switch-label:after {
    margin: 1px;
    width: 19px;
    height: 19px;
    background: white;
}

/* @toggle switch normal state focus 
--------------------------------------------------------------------------------- */
.smart-forms .switch > input:checked:focus + .switch-label { background: #3c9b39; border-color: #3c9b39; }
.smart-forms .switch-round > .switch-label {
	border-radius: 13px; 
}

.smart-forms .switch-round > .switch-label + span{ top:-2px; }
.smart-forms .switch-round > .switch-label:before { width: 33px; }
.smart-forms .switch-round > .switch-label:after {
    width: 19px;
	color:#D7D7D7;
	content: "\2022";
	font:20px/20px Times, Serif;
	border-radius: 13px;
}

.smart-forms .switch-round > input:checked + .switch-label { padding-left: 33px; }
.smart-forms .switch-round > input:checked + .switch-label:after{ color:#4ebd4a; }

/* @buttons 
----------------------------------------------------- */
.smart-forms .button {
    border: 0;
	height: 42px;
	color: #243140;
	line-height: 1;
	font-size:15px; 
    cursor: pointer;
	padding: 0 50px;
	text-align: center;
	vertical-align: top;
    background: #DBDBDB;
	display: inline-block;
	-webkit-user-drag: none;
	text-shadow: 0 1px rgba(255, 255, 255, 0.2);
}

/* @buttons :hover, :active states 
---------------------------------------------------------------- */
.smart-forms .button:hover { color: #243140; background: #E8E8E8; }
.smart-forms .button:active{ color: #1d2938; background: #C4C4C4; }
.smart-forms a.button, 
.smart-forms span.button, 
.smart-forms label.button { line-height: 42px; text-decoration: none; }
.smart-forms .button i{ font-size:14px; }
.smart-forms .button-list .button{ margin-bottom:5px; }

/* @primary button theme
-------------------------------------------- */
.smart-forms .btn-primary {  background-color: #4ebd4a;  }
.smart-forms .btn-primary:hover,
.smart-forms .btn-primary:focus { background-color: #6bc868; }
.smart-forms .btn-primary:active{ background-color: #3c9b39; }
.smart-forms .btn-primary, 
.smart-forms .btn-primary:hover,
.smart-forms .btn-primary:focus, 
.smart-forms .btn-primary:active{ color: #fff; text-shadow: 0 1px rgba(0, 0, 0, 0.08); }

/* button colors
-------------------------------------------- */
.smart-forms .btn-primary.red {  background-color: #e93e21;  }
.smart-forms .btn-primary.red:hover,
.smart-forms .btn-primary.red:focus { background-color: #242424; }
.smart-forms .btn-primary.red:active{ background-color: #e93e21; }


/* button colors
-------------------------------------------- */
/*red*/

.smart-forms .btn-primary.red {  background-color: #e93e21;  }
.smart-forms .btn-primary.red:hover,
.smart-forms .btn-primary.red:focus { background-color: #242424; }
.smart-forms .btn-primary.red:active{ background-color: #e93e21; }

/*green*/
.smart-forms .btn-primary.green {  background-color: #a5d549;  }
.smart-forms .btn-primary.green:hover,
.smart-forms .btn-primary.green:focus { background-color: #242424; }
.smart-forms .btn-primary.green:active{ background-color: #a5d549; }

/*green 4*/
.smart-forms .btn-primary.green-4 {  background-color: #a5d549;  }
.smart-forms .btn-primary.green-4:hover,
.smart-forms .btn-primary.green-4:focus { background-color: #242424; }
.smart-forms .btn-primary.green-4:active{ background-color: #a5d549; }


/*pink*/

.smart-forms .btn-primary.pink {  background-color: #8ac700;  }
.smart-forms .btn-primary.pink:hover,
.smart-forms .btn-primary.pink:focus { background-color: #242424; }
.smart-forms .btn-primary.pink:active{ background-color: #8ac700; }

/*stone*/

.smart-forms .btn-primary.stone {  background-color: #c3a254; border-radius:2px; padding:0 100px; text-align:center; margin-left:430px; vertical-align:middle; height: 60px; }
.smart-forms .btn-primary.stone:hover,
.smart-forms .btn-primary.stone:focus { background-color: #fff; color:#242424; }
.smart-forms .btn-primary.stone:active{ background-color: #c3a254; }

/*stone2*/

.smart-forms .btn-primary.stone-2 {  background-color: #c3a254;}
.smart-forms .btn-primary.stone-2:hover,
.smart-forms .btn-primary.stone-2:focus { background-color: #242424; color:#fff; }
.smart-forms .btn-primary.stone-2:active{ background-color: #c3a254; }


/*brown-2*/

.smart-forms .btn-primary.brown-2 {  background-color: #b5854d;}
.smart-forms .btn-primary.brown-2:hover,
.smart-forms .btn-primary.brown-2:focus { background-color: #242424; color:#fff; }
.smart-forms .btn-primary.brown-2:active{ background-color: #b5854d; }

/*red-4*/

.smart-forms .btn-primary.red-4 {  background-color: #f43819;}
.smart-forms .btn-primary.red-4:hover,
.smart-forms .btn-primary.red-4:focus { background-color: #242424; color:#fff; }
.smart-forms .btn-primary.red-4:active{ background-color: #f43819; }

/*red 5*/

.smart-forms .btn-primary.red-5 {  background-color: #ed3325;  }
.smart-forms .btn-primary.red-5:hover,
.smart-forms .btn-primary.red-5:focus { background-color: #242424; }
.smart-forms .btn-primary.red-5:active{ background-color: #ed3325; }

/*cyan*/

.smart-forms .btn-primary.cyan {  background-color: #42d1aa;  }
.smart-forms .btn-primary.cyan:hover,
.smart-forms .btn-primary.cyan:focus { background-color: #242424; }
.smart-forms .btn-primary.cyan:active{ background-color: #42d1aa; }

/*pink 3*/

.smart-forms .btn-primary.pink-3 {  background-color: #ce0a6c;  }
.smart-forms .btn-primary.pink-3:hover,
.smart-forms .btn-primary.pink-3:focus { background-color: #242424; }
.smart-forms .btn-primary.pink-3:active{ background-color: #ce0a6c; }


/*yellow 4*/

.smart-forms .btn-primary.yellow-4 {  background-color: #e9b444; border-radius:2px; padding:0 100px; text-align:center; margin-left:430px; vertical-align:middle; height: 60px; }
.smart-forms .btn-primary.yellow-4:hover,
.smart-forms .btn-primary.yellow-4:focus { background-color: #fff; color:#242424; }
.smart-forms .btn-primary.yellow-4:active{ background-color: #e9b444; }


/*blue*/

.smart-forms .btn-primary.blue-small {  background-color: #1fbef0; padding:0 20px;  }
.smart-forms .btn-primary.blue-small:hover,
.smart-forms .btn-primary.blue-small:focus { background-color: #fff; color:#242424; }
.smart-forms .btn-primary.blue-small:active{ background-color: #1fbef0; }



/*blue*/

.smart-forms .btn-primary.blue {  background-color: #1fbef0; }
.smart-forms .btn-primary.blue:hover,
.smart-forms .btn-primary.blue:focus { background-color: #242424; color:#fff; }
.smart-forms .btn-primary.blue:active{ background-color: #1fbef0; }

/*orange 2*/

.smart-forms .btn-primary.orange-2 {  background-color: #fd602c;}
.smart-forms .btn-primary.orange-2:hover,
.smart-forms .btn-primary.orange-2:focus { background-color: #242424; color:#fff; }
.smart-forms .btn-primary.orange-2:active{ background-color: #fd602c; }



/* @rounded buttons 
-------------------------------------------- */
.smart-forms .btn-rounded{
	border-radius:22px;  
}

/* @left + right buttons :: look like IOS
-------------------------------------------- */
.smart-forms .button-left, 
.smart-forms .button-right {
    position: relative;
	z-index:9;
}

.smart-forms .button-left:before, 
.smart-forms .button-right:before {
    content:'';
    z-index:-1;
    width: 32px;
    height: 32px;
	position: absolute;
    background-color: inherit;
	border-color: inherit;	
    border: none;
	top: 5px;
}

.smart-forms .button-left {
    border-left-width: 0; 
	padding: 0 18px 0 7px;
	border-radius: 0 3px 3px 0;
	margin-left:20px;
}

.smart-forms .button-left:before {
	left: -15px;
	border-radius: 2px 5px 0 5px;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.smart-forms .button-right {
    padding: 0 7px 0 18px;
    border-right-width: 0;
	border-radius: 3px 0 0 3px;
	margin-right:20px;
}

.smart-forms .button-right:before {
    right: -15px;
	border-radius: 5px 2px 5px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* @left right button pointed button shapes 
------------------------------------------------ */
.smart-forms .btn-pointed.button-left, 
.smart-forms .btn-pointed.button-right{ 
	border-radius:22px;  
}

.smart-forms .btn-rounded.button-left{ 
	border-radius: 0 22px 22px 0;  
}

.smart-forms .btn-rounded.button-right{
	border-radius: 22px 0 0 22px; 
}

/* @push buttons
------------------------------------------------ */
.smart-forms .pushed {
	-o-box-shadow:inset 0 -0.3em 0 rgba(0,0,0,0.2);
	box-shadow:inset 0 -0.3em 0 rgba(0,0,0,0.2);
	position:relative;
}

.smart-forms .pushed:active{
	-o-box-shadow:inset 0 -0.15em  0 rgba(0,0,0,0.2);
	box-shadow:inset 0 -0.15em  0 rgba(0,0,0,0.2);
	top:2px;
}

.smart-forms .pushed.button-left:before {
	-o-box-shadow:inset 0.35em 0 0 rgba(0,0,0,0.2);
	box-shadow:inset 0.35em 0 0 rgba(0,0,0,0.2);	
}

.smart-forms .pushed:active.button-left:before{
	-o-box-shadow:inset 0.2em 0  0 rgba(0,0,0,0.2);
	box-shadow:inset 0.2em 0  0 rgba(0,0,0,0.2);
}

.smart-forms .pushed.button-right:before {
	-o-box-shadow:inset -0.35em 0  0  rgba(0,0,0,0.2);
	box-shadow:inset -0.35em 0  0  rgba(0,0,0,0.2);	
}

.smart-forms .pushed:active.button-right:before{
	-o-box-shadow:inset -0.2em 0  0 rgba(0,0,0,0.2);
	box-shadow:inset -0.2em 0  0 rgba(0,0,0,0.2);
}

/* @adjust buttons in form footer
------------------------------------------------ */
.smart-forms .form-footer .button{ margin-right:10px; margin-bottom:5px; }
.smart-forms .align-right .button{ margin-right:0; margin-left:10px; }

/* @social buttons :: facebook :: twitter :: google +
---------------------------------------------------- */
.smart-forms .twitter, 
.smart-forms .twitter:hover, 
.smart-forms .twitter:focus,
.smart-forms .facebook, 
.smart-forms .facebook:hover, 
.smart-forms .facebook:focus,
.smart-forms .googleplus,
.smart-forms .googleplus:hover, 
.smart-forms .googleplus:focus { color:#fff; text-shadow: 0 1px rgba(0, 0, 0, 0.08); } 
.smart-forms .facebook { background-color:#3b5998; }
.smart-forms .twitter { background-color:#00acee;  }
.smart-forms .googleplus { background-color:#dd4b39; }
.smart-forms .facebook:hover, 
.smart-forms .facebook:focus { background-color:#25385F;  }
.smart-forms .twitter:hover, 
.smart-forms .twitter:focus { background-color:#00749F;  }
.smart-forms .googleplus:hover, 
.smart-forms .googleplus:focus { background-color:#8D2418;  }
.smart-forms .span-left{ padding-left:52px; text-align:left; }
.smart-forms .btn-social { position:relative; margin-bottom:5px;  }
.smart-forms .btn-social i{ font-size:22px; position:relative; top:2px;    }
.smart-forms .btn-social span{
	border-radius:3px 0 0 3px;	
	display:inline-block; 
	text-align:center; 
	position:absolute;
	width:42px; 
	left:0; 
}

.smart-forms .twitter span{ background-color:#009AD5; }
.smart-forms .facebook span{ background-color:#31497D; }
.smart-forms .googleplus span{ background-color:#C03121; }

/* @rating and review star widget :: with hover back afetr selecting
------------------------------------------------------------------------ */
.smart-forms .rating { position:relative; }
.smart-forms .rating.block { display:block; margin:10px 0; }
.smart-forms .rating label{color: #A2A6A8;} 
.smart-forms .rating label i{ font-size:17px; text-align:center; color:inherit;  }
.smart-forms .rating label span{ font:22px/22px Times, Serif; }
.smart-forms .rating-star{ margin-left:4px; }
.smart-forms .rating-input { position: absolute; left:-9999px; top: auto; }
.smart-forms .rating:hover .rating-star:hover,
.smart-forms .rating:hover .rating-star:hover ~ .rating-star,
.smart-forms .rating-input:checked ~ .rating-star { color: #4ebd4a;	}
.smart-forms .rating-star, 
.smart-forms .rating:hover .rating-star {	
	width: 18px;
	float: right;
	display: block;
	cursor:pointer;
	color: #A2A6A8;	
}

.smart-forms .rating:before,
.smart-forms .rating:after{ display: table; content: ""; line-height: 0; }
.smart-forms .rating:after{ clear: both; }
.smart-forms .rating .lbl-text{ font-size:14px; display: inline-block; float:left; }
.smart-forms .rating-wrapper {
	position:relative;
	display:inline-block;
	margin-top:0;
	float: right;
}

.smart-forms .rating label span{
	font-family:Arial, Helvetica, sans-serif;
	text-shadow: 0 1px rgba(255, 255, 255, 0);
	-webkit-transition: margin 0.6s, opacity 0.6s;
	transition: margin 0.6s, opacity 0.6s;
	border-radius:3px;	
	white-space:nowrap;
	position:absolute;
	padding:5px 10px;
	font-weight:bold;
	background:#000;
	font-size:11px;
	margin-left:-5px;
	margin-bottom:15px;
	display:block;
	left:-9999px;
	color:#fff;
	opacity:0;
	z-index:10;
}

.smart-forms .rating label:hover span{ bottom:100%; opacity:1; left: auto; margin-bottom:5px;  }
.smart-forms .rating label span:before{
	content: ''; 
	position: absolute;
	border-top: 4px solid #000;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;	
	left: 8px;
	top: 100%;
}


/* @smart widget
   @this widget helps us to position an element eg button or label or span
   @the positions can either be left or right while the input stays 100%
   @you ca use this to rapidly create search widgets, newsletter subscribe etc 
---------------------------------------------------------------------------------*/
.smart-forms .smart-widget, 
.smart-forms .append-picker-icon, 
.smart-forms .prepend-picker-icon { position: relative; display:block; }
.smart-forms .smart-widget .field input, 
.smart-forms .append-picker-icon input, 
.smart-forms .prepend-picker-icon input { width: 100%; }

.smart-forms .append-picker-icon button, 
.smart-forms .prepend-picker-icon button,
.smart-forms .smart-widget .button { 
	border:1px solid #CFCFCF;
	background: #F5F5F5;
    position: absolute;
	cursor: pointer;
	color: #626262;
	height: 42px;
    top: 0;
}

.smart-forms .sm-right .button, 
.smart-forms .append-picker-icon button{ border-left:0; }
.smart-forms .sm-left .button, 
.smart-forms .prepend-picker-icon button{ border-right:0; }

.smart-forms .sm-left .button, 
.smart-forms .prepend-picker-icon button { left:0; }
.smart-forms .sm-right .button, 
.smart-forms .append-picker-icon button {  right:0; }

/* @smart widget buttons - to left 
------------------------------------------------- */
.smart-forms .sml-50, 
.smart-forms .prepend-picker-icon { padding-left: 50px; } 
.smart-forms .sml-50 .button, 
.smart-forms .prepend-picker-icon button{ width: 50px; }
.smart-forms .sml-80{ padding-left: 80px; } 
.smart-forms .sml-80 .button { width: 80px; }
.smart-forms .sml-120{ padding-left: 120px; } 
.smart-forms .sml-120 .button { width: 120px; }

/* @smart widget buttons - to right 
------------------------------------------------- */
.smart-forms .smr-50, 
.smart-forms .append-picker-icon{ padding-right: 50px; } 
.smart-forms .smr-50 .button, 
.smart-forms .append-picker-icon button{ width: 50px; }
.smart-forms .smr-80{ padding-right: 80px; } 
.smart-forms .smr-80 .button { width: 80px; }
.smart-forms .smr-120{ padding-right: 120px; } 
.smart-forms .smr-120 .button { width: 120px; }


/* @icon append (right) :: prepend (left)
------------------------------------------------- */
.smart-forms .append-icon, 
.smart-forms .prepend-icon{
    display: inline-block;
    vertical-align: top;
    position: relative;
	width:100%;
}

.smart-forms .append-icon .field-icon, 
.smart-forms .prepend-icon .field-icon{
	top:0;
	width:42px;
	height:42px;
	color: inherit;
	line-height:42px;
	position:absolute;
	text-align: center;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    pointer-events: none;
	z-index:99;
}

.smart-forms .append-icon .field-icon i, 
.smart-forms .prepend-icon .field-icon i{ 
	position:relative;
	font-size:14px;
}

.smart-forms .prepend-icon .field-icon{ left:0;  }
.smart-forms .append-icon .field-icon{ right:0; }
.smart-forms .prepend-icon > input, 
.smart-forms .prepend-icon > textarea,
.smart-forms .prepend-icon > select{ padding-left:36px; }
.smart-forms .append-icon > input, 
.smart-forms .append-icon > textarea{ padding-right:36px; padding-left:10px;  }
.smart-forms .append-icon > textarea{ padding-right:36px; }

/* @tooltips on inputs + textareas 
------------------------------------------------- */ 
.smart-forms .tooltip {
	position: absolute;
	z-index: -1;
	opacity: 0;
	color: #fff;	
	width: 184px;
	left: -9999px;
	top:auto;
	font-size: 11px;
	font-weight:normal;
	background: #333333;
	-webkit-transition: margin 0.6s, opacity 0.6s;
	transition: margin 0.6s, opacity 0.6s;
}

.smart-forms .tooltip > em{ padding:12px; font-style:normal; display:block; position:static; }
.smart-forms .tooltip:after { content: ''; position: absolute; }
.smart-forms .option:hover .tooltip,
.smart-forms .gui-input:focus + .tooltip,
.smart-forms .gui-textarea:focus + .tooltip,
.smart-forms .select > select:focus ~ .tooltip { opacity: 1; z-index: 999; }

/* @tooltip left
------------------------------------------------- */
.smart-forms .tip-left { top:1px; margin-right:-20px; }
.smart-forms .tip-left:after {
	top:12px;
	left: 100%;
	border-left: 8px solid #333333;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
}

.smart-forms .gui-input:focus + .tip-left,
.smart-forms .gui-textarea:focus + .tip-left,
.smart-forms .select > select:focus ~ .tip-left {
	margin-right:5px;
	right: 100%;
	left: auto;
}

/* @tooltip right
------------------------------------------------- */
.smart-forms .tip-right { top:1px; margin-left:-20px; }
.smart-forms .tip-right:after {
	top:12px;
	right: 100%;
	border-right: 8px solid #333333;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;	
}

.smart-forms .gui-input:focus + .tip-right,
.smart-forms .gui-textarea:focus + .tip-right,
.smart-forms .select > select:focus ~ .tip-right { left: 100%; margin-left:5px; }

/* @tooltip right-top
------------------------------------------------- */
.smart-forms .tip-right-top { bottom: 100%; margin-bottom: -20px; }
.smart-forms .tip-right-top:after {
	top: 100%;
	right: 12px;
	border-top: 8px solid #333333;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
}

.smart-forms .gui-input:focus + .tip-right-top,
.smart-forms .gui-textarea:focus + .tip-right-top,
.smart-forms .select > select:focus ~ .tip-right-top {
	right: 0;
	left: auto;
	margin-bottom: 10px;
}

/* @tooltip left-top
------------------------------------------------- */
.smart-forms .option .tooltip,
.smart-forms .tip-left-top { bottom: 100%; margin-bottom: -20px; }
.smart-forms .option .tooltip:after,
.smart-forms .tip-left-top:after {
	top: 100%;
	left: 12px;
	border-top: 8px solid #333333;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
}
.smart-forms .option:hover .tooltip,
.smart-forms .gui-input:focus + .tip-left-top,
.smart-forms .gui-textarea:focus + .tip-left-top,
.smart-forms .select > select:focus ~ .tip-left-top {
	left: 0;
	right: auto;
	margin-bottom: 10px;
}

.smart-forms .option:hover .tooltip{
	left:-10px;
}

/* @tooltip right-bottom
------------------------------------------------- */
.smart-forms .tip-right-bottom { top: 100%; margin-top: -20px; }
.smart-forms .tip-right-bottom:after {
	right: 12px;
	bottom: 100%;
	border-bottom: 8px solid #333333;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
}

.smart-forms .gui-input:focus + .tip-right-bottom,
.smart-forms .gui-textarea:focus + .tip-right-bottom {
	margin-top: 10px;
	left: auto;
	right: 0;
}

/* @tooltip left-bottom
------------------------------------------------- */
.smart-forms .tip-left-bottom { top: 100%; margin-top: -20px; }
.smart-forms .tip-left-bottom:after {
	left: 12px;
	bottom: 100%;
	border-bottom: 8px solid #333333;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
}

.smart-forms .gui-input:focus + .tip-left-bottom,
.smart-forms .gui-textarea:focus + .tip-left-bottom {
	margin-top:10px;
	right: auto;
	left: 0;
}

/* @lists
-------------------------------------------------------------- */
.smart-forms .smart-list{ list-style:none; margin:0; padding:0; }
.smart-forms .smart-list li{ margin-bottom:20px; }

/* @notification messages | info | error | warning | success
-------------------------------------------------------------- */
.smart-forms .form-msg{ display:none; }
.smart-forms .notification { color: #444; padding:15px; position:relative; }
.smart-forms .notification p{ margin:0; padding:0 15px; padding-left:5px; line-height:normal;  }
.smart-forms .notification .close-btn{
	margin-top: -7px;
	padding: inherit;
	position: absolute;
	text-decoration:none; 
    font: bold 20px/20px Arial, sans-serif;
	opacity: 0.65; 	
	color: inherit;
    display: block;    
    right:1px;
	top:14%;
}

.smart-forms .notification .close-btn:hover{ opacity: 1; }
.smart-forms .alert-info { color:#163161; background-color: #cfe6fc; }
.smart-forms .alert-success { color:#336633; background-color: #d2f7ad; }
.smart-forms .alert-warning { color: #CC6600; background-color: #fae7a2; }
.smart-forms .alert-error { color:#990000; background-color: #FBDBCF; }
.smart-forms .notification ul{ 
	margin-left:1.3em; 
	list-style-position: inside;
	margin-top:10px;
} 

/* @validaion - error state
------------------------------------- */
.smart-forms .state-error .gui-input,
.smart-forms .state-error .gui-textarea,
.smart-forms .state-error.select > select,
.smart-forms .state-error.select-multiple > select,
.smart-forms .state-error input:hover + .checkbox, 
.smart-forms .state-error input:hover + .radio,
.smart-forms .state-error input:focus + .checkbox, 
.smart-forms .state-error input:focus + .radio,
.smart-forms .state-error .checkbox, 
.smart-forms .state-error .radio{
	background:#FEE9EA;
	border-color:#DE888A;
}

.smart-forms .state-error .gui-input:focus,
.smart-forms .state-error .gui-textarea:focus,
.smart-forms .state-error.select > select:focus,
.smart-forms .state-error.select-multiple > select:focus{
	-o-box-shadow:0px 0px 3px #DE888A inset;
	box-shadow:0px 0px 3px #DE888A inset;
}

.smart-forms .state-error .gui-input ~ .field-icon i,
.smart-forms .state-error .gui-textarea ~ .field-icon i{  color: #DE888A; }
.smart-forms .state-error.select .arrow { color: #DE888A; }
.smart-forms .state-error.select > select:focus + .arrow{ color:#DE888A; }
.smart-forms .state-error .gui-input ~ .input-hint,
.smart-forms .state-error.file .gui-file:hover + .gui-input, 
.smart-forms .state-error .gui-textarea ~ .input-hint { border-color:#DE888A; }
.smart-forms .state-error + em{ 
	display: block!important;
	margin-top: 6px;
	padding: 0 3px;
	font-family:Arial, Helvetica, sans-serif;
	font-style: normal;
	line-height: normal;
	font-size:0.85em;
	color:#DE888A;
}

/* @validaion - success state 
-------------------------------------------------- */
.smart-forms .state-success .gui-input,
.smart-forms .state-success .gui-textarea,
.smart-forms .state-success.select > select,
.smart-forms .state-success.select-multiple > select,
.smart-forms .state-success input:hover + .checkbox, 
.smart-forms .state-success input:hover + .radio,
.smart-forms .state-success input:focus + .checkbox, 
.smart-forms .state-success input:focus + .radio, 
.smart-forms .state-success .checkbox, 
.smart-forms .state-success .radio{
	background:#F0FEE9;
	border-color:#A5D491;
}

.smart-forms .state-success .gui-input:focus,
.smart-forms .state-success .gui-textarea:focus,
.smart-forms .state-success.select > select:focus,
.smart-forms .state-success.select-multiple > select:focus{ 
	-o-box-shadow:0px 0px 3px #A5D491 inset; 
	box-shadow:0px 0px 3px #A5D491 inset; 
}

.smart-forms .state-success .gui-input ~ .field-icon i,
.smart-forms .state-success .gui-textarea ~ .field-icon i{  color: #A5D491; }
.smart-forms .state-success.select .arrow { color: #A5D491; }
.smart-forms .state-success.select > select:focus + .arrow{ color:#A5D491; }
.smart-forms .state-success .gui-input ~ .input-hint,
.smart-forms .state-success.file .gui-file:hover + .gui-input, 
.smart-forms .state-success .gui-textarea ~ .input-hint { border-color:#A5D491; }

/* @disabled state 
----------------------------------------------- */
.smart-forms .button[disabled],
.smart-forms .state-disabled .button,
.smart-forms input[disabled] + .radio,
.smart-forms input[disabled] + .checkbox,
.smart-forms .switch > input[disabled] + label{
	cursor: default;
	opacity:0.5;
}

.smart-forms .gui-input[disabled],
.smart-forms .gui-textarea[disabled], 
.smart-forms .select > select[disabled], 
.smart-forms .select-multiple select[disabled],
.smart-forms .gui-input[disabled] ~ .input-hint,
.smart-forms .file .gui-file[disabled] + .gui-input,
.smart-forms .file .gui-file[disabled]:hover + .gui-input, 
.smart-forms .gui-textarea[disabled] ~ .input-hint {    
	background-color: #f4f6f6;
	border-color: #d5dbdb!important;
	cursor: default;
	color: #d5dbdb;
	opacity:0.7;
}

.smart-forms input[disabled] ~ .field-icon i,
.smart-forms textarea[disabled] ~ .field-icon i,
.smart-forms .select > select[disabled] + .arrow{ 
	opacity:0.4; 
}

/* @jquery ui helper classes 
----------------------------------------------- */
.ui-helper-hidden {
	display: none;
}

.ui-helper-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.ui-helper-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	line-height: 1.3;
	text-decoration: none;
	font-size: 100%;
	list-style: none;
}
.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
	content: "";
	display: table;
	border-collapse: collapse;
}
.ui-helper-clearfix:after {
	clear: both;
}
.ui-helper-clearfix {
	min-height: 0;
}
.ui-helper-zfix {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	filter:Alpha(Opacity=0);
}

.ui-front {
	z-index: 100;
}

/* @datepicker - requires jquery ui
----------------------------------------------- */
.ui-datepicker {
	width: 18em;
	margin-top:8px;
	display: none;
	background: #fff;
	position:relative;
	font: 14px/1.55  "Roboto", Arial, Helvetica, sans-serif;
	-o-box-shadow: 0 0 4px rgba(0,0,0,.1);
	box-shadow: 0 0 4px rgba(0,0,0,.1);
	border:1px solid #CFCFCF;
	z-index:9999!important;		
	text-align: center;
	color: #666;
	
}

.ui-datepicker a { color: #404040; text-align:center; }
.ui-datepicker .ui-state-disabled span{ color:#DBDBDB;}
.ui-datepicker .ui-datepicker-header {
	position: relative;
	background: #F5F5F5;
	border-bottom:1px solid #CFCFCF;
	line-height: 27px;
	font-size: 15px;
	padding: 10px;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
	width: 34px;
	height: 34px;
	display: block;
	font-size: 14px;
	position: absolute;
	text-decoration: none;
	cursor: pointer;
	color:#4ebd4a;
	top:20.5%;
}

.ui-datepicker .ui-datepicker-prev { left: 2px;  }
.ui-datepicker .ui-datepicker-next { right: 2px; }
.ui-datepicker .ui-datepicker-title { 
	margin: 0 2.3em; 
	line-height: 1.8em; 
	text-align: center;
	color:#4ebd4a; 
}

.ui-datepicker .ui-datepicker-title select { font-size: 1em; margin: 1px 0; }
.ui-datepicker select.ui-datepicker-month-year { width: 100%; }
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year { width: 49%; }
.ui-datepicker table {
	width: 100%;
	font-size: .9em;
	margin: 0 0 .4em;
	border-collapse: collapse;
}

.ui-datepicker th {
	padding: .5em .3em;
	text-align: center;
	font-weight: bold;
	border: 0;
}

.ui-datepicker td { border: 0; padding:2px 5px; }
.ui-datepicker td span,
.ui-datepicker td a {
	padding: .25em;
	display: block;
	text-align: center;
	text-decoration: none;
}

.ui-datepicker td span:hover,
.ui-datepicker td a:hover {  background:#F5F5F5; }
.ui-datepicker .ui-state-disabled span:hover{ background:none; }
.ui-datepicker-today a, .ui-datepicker-today a:hover, 
.ui-datepicker .ui-state-highlight {
	font-weight: 700;
	background: #4ebd4a!important;
	color:#fff;
}

/* @multiple calendars || not responsive use carefully 
--------------------------------------------------------------- */
.cal-widget .ui-datepicker { width: 100%; margin-top:0; }
.cal-widget .ui-datepicker:before{ display:none; }
.ui-datepicker.ui-datepicker-multi { width: auto; }
.ui-datepicker-multi .ui-datepicker-group { float: left; }
.ui-datepicker-multi .ui-datepicker-group table { width: 95%; margin: 0 auto .4em; }
.ui-datepicker-multi-2 .ui-datepicker-group { width: 50%; }
.ui-datepicker-multi-3 .ui-datepicker-group { width: 33.333%; }
.ui-datepicker-multi-4 .ui-datepicker-group { width: 25%; }
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header { border-left-width: 0; }
.ui-datepicker-multi .ui-datepicker-buttonpane { clear: left; }
.ui-datepicker-row-break { clear: both; width: 100%; font-size: 0; }

/* @ ui buttons
---------------------------------------------------------------- */
.ui-datepicker-buttonpane{ border-top:1px solid #CFCFCF; padding:10px;  }
.ui-datepicker-buttonpane button {
	padding: 8px 12px;
	margin-right: .2em;
	position: relative;
	line-height: normal;
	display: inline-block;
	-webkit-user-drag: none;
	text-shadow: 0 1px rgba(255, 255, 255, 0.2);
	vertical-align: middle;
	background: #DBDBDB;
	text-align: center;
	overflow: visible;	
	cursor: pointer;
	color: #243140;
	border:0;
}

/* @ ui buttons :hover, :active states 
---------------------------------------------------------------- */
.ui-datepicker-buttonpane button:hover { color: #243140; background: #E8E8E8; }
.ui-datepicker-buttonpane button:active{ color: #1d2938; background: #C4C4C4; }
.ui-monthpicker .ui-datepicker-header{ margin-bottom:3px; }

/* @ui slider - requires jquery ui
------------------------------------------------------*/
.smart-forms .slider-wrapper, 
.smart-forms .sliderv-wrapper{ 
	background:#E5E5E5; 
	position:relative; 
}

.smart-forms .ui-slider {
	position: relative;
	text-align: left;
}

.smart-forms .ui-slider .ui-slider-handle {
	position: absolute;
	z-index: 2;
	width: 1.5em;
	height: 1.5em;
	cursor: default;
	background:#fff;
	border:3px solid #4ebd4a;
	border-radius:20px;
	touch-action: none;
	margin-top:-3px;
	outline:none;
}

.smart-forms .ui-slider .ui-slider-handle:before{
	content: '';
    width: 7px;
    height: 7px;
	position:absolute;
    background-color: #4ebd4a; 
	border-radius: 10px;
	z-index: 2;	
	left:4px;
	top:4px;
}

.smart-forms .ui-slider .ui-slider-range {
	position: absolute;
	z-index: 1;
	font-size: .7em;
	display: block;
	border: 0;
	background-position: 0 0;
	background-color: #4ebd4a;
}

.smart-forms .ui-slider.ui-state-disabled .ui-slider-handle,
.smart-forms .ui-slider.ui-state-disabled .ui-slider-range { -webkit-filter: inherit; filter: inherit; }
.smart-forms .ui-slider-horizontal { height: .5em; }
.smart-forms .ui-slider-horizontal .ui-slider-handle { top: -.3em; margin-left: -.6em; }
.smart-forms .ui-slider-horizontal .ui-slider-range { top: 0; height: 100%; }
.smart-forms .ui-slider-horizontal .ui-slider-range-min { left: 0; }
.smart-forms .ui-slider-horizontal .ui-slider-range-max { right: 0; }
.smart-forms .ui-slider-vertical, 
.smart-forms .sliderv-wrapper { width: .5em; height: 100px; }
.smart-forms .ui-slider-vertical .ui-slider-handle { left: -.45em; margin-left: 0; margin-bottom: -.6em; }
.smart-forms .ui-slider-vertical .ui-slider-range { left: 0; width: 100%; }
.smart-forms .ui-slider-vertical .ui-slider-range-min { bottom: 0; }
.smart-forms .ui-slider-vertical .ui-slider-range-max { top: 0; }
.smart-forms .slider-input{  color:#f6931f!important; border:0; background:none; }
.smart-forms .slider-group .sliderv-wrapper{ height:150px; float:left; margin:15px 15px;   }
.smart-forms .ui-slider .ui-state-active {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

/* @ui slider tooltip
------------------------------------------------------*/
.smart-forms .slider-tip {
	display: block;
	position: absolute;
	text-align: center;
	font: 10pt Tahoma, Arial, sans-serif ;
	background: #333333;
	padding:10px;
	color: #fff;
}

.smart-forms .slider-wrapper .slider-tip{ top: -50px; left:-15px; }
.smart-forms .slider-wrapper .slider-tip:after { 
	content: ''; 
	position: absolute; 
	top: 98%;
	left: 35%;
	border-top: 8px solid #333333;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;	
}

.smart-forms .sliderv-wrapper .slider-tip{ left: 30px; top:-12px; }
.smart-forms .sliderv-wrapper .slider-tip:after{ 
	content: ''; 
	position: absolute;
	top:30%;
	right: 98%;
	border-right: 8px solid #333333;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;	
}

/* @ui slider themes
------------------------------------------------------*/
.smart-forms .yellow-slider .ui-slider .ui-slider-handle{ border-color:#faa226; }
.smart-forms .yellow-slider .ui-slider .ui-slider-handle:before,
.smart-forms .yellow-slider .ui-slider .ui-slider-range { background-color: #faa226;  }
.smart-forms .red-slider .ui-slider .ui-slider-handle{ border-color:#ee4f3d; }
.smart-forms .red-slider .ui-slider .ui-slider-handle:before,
.smart-forms .red-slider .ui-slider .ui-slider-range { background-color:#ee4f3d;  }
.smart-forms .purple-slider .ui-slider .ui-slider-handle{ border-color:#9464e2; }
.smart-forms .purple-slider .ui-slider .ui-slider-handle:before,
.smart-forms .purple-slider .ui-slider .ui-slider-range { background-color:#9464e2;  }
.smart-forms .blue-slider .ui-slider .ui-slider-handle{ border-color:#00acee; }
.smart-forms .blue-slider .ui-slider .ui-slider-handle:before,
.smart-forms .blue-slider .ui-slider .ui-slider-range { background-color:#00acee;  }
.smart-forms .black-slider .ui-slider .ui-slider-handle{ border-color:#505558; }
.smart-forms .black-slider .ui-slider .ui-slider-handle:before,
.smart-forms .black-slider .ui-slider .ui-slider-range { background-color:#505558;  }
.smart-forms .green-slider .ui-slider .ui-slider-handle{ border-color:#0E993C; }
.smart-forms .green-slider .ui-slider .ui-slider-handle:before,
.smart-forms .green-slider .ui-slider .ui-slider-range { background-color:#0E993C;  }

/* @ui timepicker - requires jquery ui
------------------------------------------------------*/
.ui-timepicker-div .ui-widget-header {
	position: relative;
	background: #F5F5F5;
	line-height: 27px;
	font-size: 15px;
	padding: 10px;
 }
 
.ui-timepicker-div dl { text-align: left; border:1px solid #CFCFCF; border-width:1px 0 0 0; padding:15px 10px; padding-bottom:15px; margin:0;  }
.ui-timepicker-div dl dt { float: left; clear:left; padding: 0 0 0 5px; }
.ui-timepicker-div dl dd { margin: 0 10px 15px 40%; }
.ui-timepicker-div td { font-size: 90%; }
.ui-tpicker-grid-label { background: none; border: none; margin: 0; padding: 0;    }
.ui-timepicker-div .ui_tpicker_unit_hide{ display: none; }

.ui-timepicker-rtl{ direction: rtl; }
.ui-timepicker-rtl dl { text-align: right; padding: 0 5px 0 0; }
.ui-timepicker-rtl dl dt{ float: right; clear: right; }
.ui-timepicker-rtl dl dd { margin: 0 40% 10px 10px; background:#E5E5E5;  position:relative; top:6px;  }

.ui-timepicker-div dl .ui_tpicker_hour, 
.ui-timepicker-div dl .ui_tpicker_minute, 
.ui-timepicker-div dl .ui_tpicker_second,  
.ui-timepicker-div dl .ui_tpicker_millisec{ background:#E5E5E5;  position:relative;  top:5px;  }

/* Shortened version style */
.ui-timepicker-div.ui-timepicker-oneLine { padding-right: 2px; }
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_time, 
.ui-timepicker-div.ui-timepicker-oneLine dt { display: none;   }
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_time_label { display: block; padding-top: 2px; }
.ui-timepicker-div.ui-timepicker-oneLine dl { text-align: right;}
.ui-timepicker-div.ui-timepicker-oneLine dl dd, 
.ui-timepicker-div.ui-timepicker-oneLine dl dd > div { display:inline-block; margin:0;  }
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_minute:before,
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_second:before { content:':'; display:inline-block;   }
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_millisec:before,
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_microsec:before { content:'.'; display:inline-block;  }
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_unit_hide,
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_unit_hide:before{ display: none; }

/* ui-autocomplete widget
----------------------------------------------------- */
.ui-autocomplete {
	position: absolute;
	top: 0;
	left: 0;
	cursor: default;
	z-index: 10000000;	
	list-style: none;
	padding: 0;
	margin: 0;
	display: block;
	outline: none;
	font-family:  "Roboto", Arial, Helvetica, sans-serif;
	font-size:14px;
	border:1px solid #bbb;
	border-top:0;
	background:#fff;
	max-height: 100px;
	overflow-x: hidden;
	overflow-y: auto;	
}

.ui-autocomplete .ui-menu { position: absolute; }
.ui-autocomplete .ui-menu-item {
	position: relative;
	margin: 0;
	padding: 8px 10px;
	cursor: pointer;
	min-height: 0;
	list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
	background:#F2F4FA;
	border-bottom:1px solid #DCE1EE;
	-o-box-shadow: inset 0 1px #fff, inset 0 0 0 1px rgba(255, 255, 255, 0.5);
 box-shadow: inset 0 1px #fff, inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

.ui-autocomplete .ui-menu-divider {
	margin: 5px 0;
	height: 0;
	font-size: 0;
	line-height: 0;
	border-width: 1px 0 0 0;
	border-color:#ddd;
}

.ui-autocomplete .ui-state-focus,
.ui-autocomplete .ui-state-active { }
.ui-autocomplete .ui-menu-item:hover{
	background:#DCE1EE;
}

/* @jquery ui combobox widget
---------------------------------------- */
.custom-combobox {
	position: relative;
	padding-right:45px;
	display: block;
}
.custom-combobox-toggle {
	position: absolute;
	top: 0;
	bottom: 0;
	right:0;
	margin-left: -1px;
	padding: 0;
	z-index:10;
	width:45px;
	line-height:40px;
	height:100%;
	text-align:center;
	background:#F5F5F5;
	border:1px solid #CFCFCF;
	border-left:0;
	cursor:pointer;
	color:#aaa;
}
.custom-combobox-input {
	margin: 0;
	padding: 5px 10px;
}

/* @progress bars 
------------------------------------------------------*/
.smart-forms .progress-section{ display:none; } 
.smart-forms .progress-bar {
    position: relative;
    background:#E5E5E5; 
}

.smart-forms .progress-bar .percent { 
	position:absolute; 
	display:inline-block; 
	top:-3px; 
	right:-24px; 
	font-size:9px; 
	color:#93A2AA; 
}
.smart-forms .progress-bar > .bar {   
	width:60%; 
	height:7px;
	display: block;
	background-size: 16px 16px;	
	background-color: #bdc3c7; 
}

/* @progress bar themes
----------------------------------------------------------- */
.smart-forms .bar-primary > .bar  { background-color: #4ebd4a; }
.smart-forms .bar-blue > .bar     { background-color: #00acee; }
.smart-forms .bar-black > .bar    { background-color: #505558; }
.smart-forms .bar-green > .bar    { background-color: #0E993C; }
.smart-forms .bar-purple > .bar   { background-color: #9464e2; }
.smart-forms .bar-red > .bar      { background-color: #ee4f3d; }
.smart-forms .bar-yellow > .bar   { background-color: #faa226; }

/* @progress bar strips + animation IE10+ 
----------------------------------------------------------- */
.smart-forms .ui-slider .ui-slider-range,  
.smart-forms .progress > button[type="submit"], 
.smart-forms .progress > button[type="submit"]:hover,
.smart-forms .progress-bar > .bar{
    background-size: 16px 16px;
					  
    background-image: -webkit-gradient(linear,  
					  left top, right bottom,  
					  from(transparent), color-stop(25%, transparent), color-stop(25%, rgba(255, 255, 255, 0.3)), color-stop(50%, rgba(255, 255, 255, 0.3)), 
					  color-stop(50%, transparent), color-stop(75%, transparent), color-stop(75%, rgba(255, 255, 255, 0.3)), to(rgba(255, 255, 255, 0.3)));
					  
    background-image: linear-gradient(to bottom right,  
					  transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, 
					  transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
}

.smart-forms .progress > button[type="submit"], 
.smart-forms .progress > button[type="submit"]:hover,  
.smart-forms .progress-animated > .bar{
	-webkit-animation: sfprogress .6s linear infinite;
    animation: sfprogress .6s linear infinite;
}

.smart-forms .progress > button[type="submit"]:hover{ cursor:wait; }

@-webkit-keyframes sfprogress {
    from { background-position: 0 0; }
	to { background-position: -16px 0; }
}

@keyframes sfprogress {
    from { background-position: 0 0; }
	to { background-position: -16px 0; }
}

/* @cloned widgets 
--------------------------------------------------------------- */
.clone-widget{ padding-right:100px; position:relative;  }
.clone-widget .button{ position:absolute; right:0; top:0;}
.clone-widget .clone{ right:50px; }

/* @google map :: block elements 
----------------------------------------------------------------------- */
.smart-forms .map-container{ padding:10px; border: 1px solid #CFCFCF; }
.smart-forms #map_canvas{ width:100%; height:300px; overflow:hidden;  }
.smart-forms .block{ display:block; }

/* @form grid
----------------------------------- */ 

/* @form rows 
--------------------------------- */
.smart-forms .frm-row{ margin:0 -10px;  }
.smart-forms .slider-group:before,
.smart-forms .slider-group:after,
.smart-forms .frm-row:before,
.smart-forms .frm-row:after { display: table; content: ""; line-height: 0; }
.smart-forms .slider-group:after, 
.smart-forms .frm-row:after{ clear: both; }

/* @form columns 
----------------------------------- */
.smart-forms .frm-row .colm{ 
	min-height:1px; 
	padding-left:10px; 
	padding-right:10px; 
	position:relative; 
	float:left; 
}

.smart-forms .frm-row .colm1{width:8.33%;}
.smart-forms .frm-row .colm2{width:16.66%;}
.smart-forms .frm-row .colm3{width:25%;}
.smart-forms .frm-row .colm4{width:33.33%;}
.smart-forms .frm-row .colm5{width:41.66%;}
.smart-forms .frm-row .colm6{width:50%;}
.smart-forms .frm-row .colm7{width:58.33%;}
.smart-forms .frm-row .colm8{width:66.66%;}
.smart-forms .frm-row .colm9{width:75%;}
.smart-forms .frm-row .colm10{width:83.33%;}
.smart-forms .frm-row .colm11{width:91.66%;}
.smart-forms .frm-row .colm12{width:100%; }
.smart-forms .frm-row .colm1-5{width:20%;}
.smart-forms .frm-row .colm1-8{width:12.5%;}

/* @spacers 
--------------------------------------- */
.smart-forms .spacer{ 
	border-top:1px solid #CFCFCF; 
	display:block;
	height:0; 
}

/* @margin spacers :: modify accordingly 
-------------------------------------------- */
.smart-forms .spacer-t10{ margin-top:10px; }
.smart-forms .spacer-b10{ margin-bottom:10px; }
.smart-forms .spacer-t15{ margin-top:15p; }
.smart-forms .spacer-b15{ margin-bottom:15px; }
.smart-forms .spacer-t20{ margin-top:20px; }
.smart-forms .spacer-b20{ margin-bottom:20px; }
.smart-forms .spacer-t25{ margin-top:25px; }
.smart-forms .spacer-b25{ margin-bottom:25px; }
.smart-forms .spacer-t30{ margin-top:30px; }
.smart-forms .spacer-b30{ margin-bottom:30px; }
.smart-forms .spacer-t40{ margin-top:40px; }
.smart-forms .spacer-b40{ margin-bottom:40px; }

/* @padding spacers :: modify accordingly 
-------------------------------------------------- */
.smart-forms .frm-row .pad-l10{ padding-left:10px; }
.smart-forms .frm-row .pad-r10{ padding-right:10px; }
.smart-forms .frm-row .pad-l20{ padding-left:20px; }
.smart-forms .frm-row .pad-r20{ padding-right:20px; }
.smart-forms .frm-row .pad-l30{ padding-left:30px; }
.smart-forms .frm-row .pad-r30{ padding-right:30px; }
.smart-forms .frm-row .pad-l40{ padding-left:40px; }
.smart-forms .frm-row .pad-r40{ padding-right:40px; }

/* @border spacers + text adjust
-------------------------------------------------- */
.smart-forms .bdl { border-left:1px solid #CFCFCF;   }
.smart-forms .bdr { border-right:1px solid #CFCFCF;  }
.smart-forms .fine-gray{ color:#999; }
.smart-forms .small-text{ font-size:11px; font-style:normal;  }
.smart-forms .text-align{ height:42px; line-height:42px; }

/* @element alignment
-------------------------------------------------- */
.smart-forms .align-right{ text-align:right; }
.smart-forms .align-center{ text-align:center; }

/* @simple price boxes :: depend on grid
-------------------------------------------------- */
.smart-forms .price-box{ 
	padding:30px; 
	text-align:center;
	position:relative; 
	border:1px solid #CFCFCF;
	font-family:Arial, Helvetica, sans-serif;
	-o-box-shadow:  0px 2px 0px 0px rgba(0, 0, 0, 0.05);
	box-shadow:  0px 2px 0px 0px rgba(0, 0, 0, 0.05); 
}

.smart-forms .price-box p{ line-height:1.5em; color:#526066; margin-bottom:0; }
.smart-forms .price-box h5{ text-transform:uppercase; font-weight:300; margin:0; font-size:15px; color:#B0B2B9; letter-spacing:2px  }
.smart-forms .price-box h4{ font-size:60px; font-weight:300; margin:0; color:#626262; }
.smart-forms .selected-box h4{ color:#4ebd4a; }
.smart-forms .price-box h4 sup{ position:relative; font-size:30px; vertical-align:top; top:15px; }
.smart-forms .price-box h4 .per-month{font-size:14px; }
.smart-forms .expand{ height:50px; line-height:50px!important; border-radius:3px; }

/* @simple price boxes ribbon IE8+
----------------------------------------- */
.smart-forms .ribbon,
.smart-forms .ribbon-large{
	width:75px;
	height:78px;
	overflow:hidden;
	position:absolute;
	right: -2px;
	top: -2px;
	z-index:1;
}

.smart-forms .ribbon-inner{
	font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;
	-o-box-shadow:  0px 2px 0px 0px rgba(0, 0, 0, 0.15);
	box-shadow:  0px 2px 0px 0px rgba(0, 0, 0, 0.15);
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000;
	-webkit-transform:rotate(45deg);
	transform:rotate(45deg);
	background: #4ebd4a;
	letter-spacing:4px;
	text-align:center;
	position:relative;
	font-weight:700;
	font-size:14px;
	padding:7px 0;
	width:100px;
	color:#fff;
	z-index:1;
	left:3px;
	top:6px;
}

.smart-forms .ribbon-inner:before,
.smart-forms .ribbon-inner:after{
	content:"";
	border-top:3px solid #3c9b39;
	border-left:3px solid transparent;
	border-right:3px solid transparent;
	position:absolute;
	bottom:-3px;
}

.smart-forms .ribbon-inner:before{ left:0px; }
.smart-forms .ribbon-inner:after{ right:0px; }
.smart-forms .ribbon-large{ width:115px; height:118px; }
.smart-forms .ribbon-large .ribbon-inner{
	width:160px;
	left:-8px;
	top:28px;
}

/* @captcha refresh button + icon 
-------------------------------------------------- */
.smart-forms .sfcode{ padding-left:24px; }
.smart-forms .captcode{ padding:0; position:relative; }
.smart-forms .captcode img{ position:relative; top:1px;}
.smart-forms .refresh-captcha{ 
	position:absolute;
	background:#4EBD4A;
	border:3px solid #3C9B39;
	border-radius:30px;
	right:-15px;
	height:32px;
	width:32px; 
	top:4px; 
}

.smart-forms .refresh-captcha i{ 
	position:absolute; 
	text-align:center; 
	line-height:26px;
	font-size:17px; 
	color:#fff;
	left:24%;
}

/* @captcha refresh button themes
-------------------------------------------------- */
.smart-forms .refresh-black    { background:#505558; border-color: #333333; }
.smart-forms .refresh-blue     { background:#00acee; border-color: #0087bb; }
.smart-forms .refresh-green    { background:#0E993C; border-color: #0B792F; }
.smart-forms .refresh-purple   { background:#9464e2; border-color: #7639da; }
.smart-forms .refresh-red      { background:#ee4f3d; border-color: #e42914; }
.smart-forms .refresh-yellow   { background:#faa226; border-color: #e88a05; }

/* Firefox select fix - select arrow hack  disabled on FF 30+
-------------------------------------------------------------- */
@-moz-document url-prefix() {
	.smart-forms .select:before{ 
		content: '';
		pointer-events:none;
		-webkit-transition:none;
		transition:none;
		position: absolute; 
		background: #F5F5F5; 
		width: 36px;
		right:1px;
		top:1px; 
		bottom:1px;
		z-index:99;  
	}
	
	.smart-forms .select > select:focus,
	.smart-forms .select > select:hover,
	.smart-forms .select:hover select,
	.smart-forms .select:hover:before{ 
		background: #fff;
		-webkit-transition:none;
		transition:none;  	
		box-shadow:none;		
	}
	
	.smart-forms .select .arrow {  z-index:100;  }
	.smart-forms .state-error.select > select:focus,
	.smart-forms .state-error.select > select:hover,
	.smart-forms .state-error.select:hover select,
	.smart-forms .state-error.select:hover:before,	
	.smart-forms .state-error.select:before { background:#FEE9EA;  }
	
	.smart-forms .state-success.select > select:focus,
	.smart-forms .state-success.select > select:hover,
	.smart-forms .state-success.select:hover select,
	.smart-forms .state-success.select:hover:before,		
	.smart-forms .state-success.select:before { background:#F0FEE9;  }	
			
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.smart-forms .select .arrow:after,
	.smart-forms .select .arrow:before { display:block; }
}

/* @Fix old of versions android + ios
------------------------------------------------------------- */
@media screen and (-webkit-min-device-pixel-ratio:0) { 
        .smart-forms .option, 
        .smart-forms .rating, 
        .smart-forms .switch, 
		.smart-forms .captcode { -webkit-animation: bugfix infinite 1s; }
        @-webkit-keyframes bugfix { 
            from { padding: 0;  } 
            to { padding: 0; } 
        }
		.smart-forms .switch { margin-right:10px;  margin-bottom:5px; }
		.smart-forms .option { margin-right:15px; }
		.smart-forms .switch-round > .switch-label:after{ font:20px/18px Times, Serif; }				
}

/* @responsiveness for tablets + smart mobile 
-------------------------------------------------- */
@media (max-width: 800px) {
	.smart-forms .frm-row{ margin:0;  }
	.smart-forms .frm-row .colm{  width: 100%; float:none; padding:0; }
	.smart-forms .bdl { border-left:0;  }
	.smart-forms .bdr { border-right:0; }
	.smart-forms .align-right{ text-align: left; }
}

@media only screen and (min-width: 640px) and (max-width: 767px){
	
.section-form.less-width {
    width: 100%;
}
.smart-forms .btn-primary.yellow-4 {
    margin-left: 0;
}

}

@media only screen and (min-width: 480px) and (max-width: 639px){
	
.section-form.less-width {
    width: 100%;
}
.smart-forms .btn-primary.yellow-4 {
    margin-left: 0;
}

}
@media only screen and (max-width: 479px){
	
.section-form.less-width {
    width: 100%;
}
.smart-forms .btn-primary.yellow-4 {
    margin-left: 0;
}

}