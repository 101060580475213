.subtitle{
    color: #CC6600;
  }

  .form-gap {
    padding-top: 70px;
}

.forgot-bg {
  background-image: url("../images//login-register.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  }
