/* Panel */
.panel-footer {
    text-align: center;
}

.panel-footer > .btn {
    padding: 8px 30px;
    margin: 5px;
    box-shadow: none;
    border-radius: 6px;
    background-color: #f5f5f5;
    border: 1px solid #997217;
    font-weight: bold;
}

.panel-footer > .btn:disabled {
    padding: 8px 30px;
    margin: 5px;
    box-shadow: none;
    border-radius: 6px;
    background-color: #f5f5f5;
    border: 1px solid #997217;
    font-weight: lighter;
}

.panel-heading > .btn{
    padding: 5px;
    margin: 0;
    box-shadow: none;
    background-color: #f5f5f5;
    border-radius: 8px;
    border: none;
}

.panel-body > form > .form-group > .tip {
    font-size: 10px;
    margin-left: 5px;
    font-weight: bold;
}

.panel-footer > .custom-file-upload > input[type="file"] {
    display: none;
}

.panel-footer > .custom-file-upload {
    border: 1px solid #997217;
    font-size: 12px;
    display: inline-block;
    padding: 6px 12px;
    margin: 5px;
    box-shadow: none;
    border-radius: 6px;
    cursor: pointer;
}



/* Everything here overwrite the modal from funds register */
.modal-footer {
    padding: 20px;
    text-align: center;
    border-top: 1px solid #e5e5e5;
}

.modal-footer .btn {
    margin-right: 7px;
    margin-top: 7px;
    color:#727272;
    background-color: #f5f5f5;
    border: 1px solid #997217;
    font-weight: bold;
}

.modal-body-fundo {
    width: 80%;
    margin-left: 10.33333333%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px; 
    padding: 15px;
}

.modal-content-invest {
    position: relative;
    width: 100%;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    outline: 0;
    box-shadow: none;
}


/* Remove input button (Vinculo) */
.modal-body-fundo > form > .form-group > .row > .col-sm-8 > .input-group > .input-group-btn > button {
    margin-left: -1px;
    color: red;
    padding: 11px 13px;
    margin-bottom: 10px;
    font-size: 10px;
}

/* Remove button on table */
.text-center > .input-group-btn > .btn {
    color: #d01126;
    border: none;
    padding: 2px;
    font-size: 20px;
    background-color: #fff;
}

/* Overwrite on form control (Fundo) */
.modal-body-fundo > form >  .row > .col-sm-8 .form-control {
    margin-bottom: 15px;
    min-height: 34px;
}

.help-block {
    font-size: 12px;
    display: block;
    margin-top: 0px;
    margin-bottom: 0px;
}

/* Modal vinculo */

.input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: top;
}